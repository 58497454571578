
import { Vue, Options } from "vue-class-component";
import DropDown from "@/components/DropDown.vue";

@Options({
  components: {
    DropDown,
  },
  methods: {
    printSelectedValue(item: any) {
      this.selectedValue = item.label + " " + item.value;
    },
  },
  data() {
    return {
      items: [
        { value: "a-2098", label: "Group 1" },
        { value: "a-2099", label: "Group 2" },
      ],
      selectedValue: "",
    };
  },
})
export default class TestDropDown extends Vue {}
