<template>
  <drop-down
    :items="items"
    @on-select="printSelectedValue($event)"
    show-item-value="true"
    show-search="true"
  />
  <br />
  <h3>{{ selectedValue }}</h3>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import DropDown from "@/components/DropDown.vue";

@Options({
  components: {
    DropDown,
  },
  methods: {
    printSelectedValue(item: any) {
      this.selectedValue = item.label + " " + item.value;
    },
  },
  data() {
    return {
      items: [
        { value: "a-2098", label: "Group 1" },
        { value: "a-2099", label: "Group 2" },
      ],
      selectedValue: "",
    };
  },
})
export default class TestDropDown extends Vue {}
</script>
